.assist-panel {
  h1, h2 {
    padding: 1em 0;
    font-weight: 800;
  }
  h1 {
    
  }
  .button-group {
    display: flex;
    justify-content: stretch;
  }
  padding: 1em;
  button.assist-btn {
    box-shadow: none;
    padding: 2em 0.5em;
  }
}
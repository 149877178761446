@import './_vars.scss';

.reservations-panel {
  max-width: 720px;
  margin: 0 auto;
  .header {
    margin-bottom: 1em;
  }
  h1, h2 {
    padding: 1em 0;
    font-weight: 800;
  }
  h1 {
    font-size: 2em;
  }
  p {
    padding: 0.2em 0em;
    line-height: 1.2em;
  }
  .button-group {
    display: flex;
    justify-content: stretch;
  }
  padding: 1em;
  button.assist-btn {
    box-shadow: none;
    padding: 2em 0.5em;
  }
  label .text {
    // text-align: center;
    display: inline-block;
    min-width: 6em;
  }
  .slots {
    display: flex;
    flex-wrap: wrap;
    max-width: 720px;
  }
  .slot-wrapper {
    box-sizing: border-box;
    width: 25%;
    padding: 0.2em 0.2em;
  }
  .slot {
   font-size: 0.8em;
    
  
    border-radius: 0.5em;
    border: 1px solid rgb(200, 200, 200);
    label {
      cursor: pointer;
      padding: 1em 0em;
      text-align: center;
      width: 100%;
      height: 100%;
    }
    input[type="radio"] {
      clip-path: inset(100%);
      clip: rect(1px 1px 1px 1px); /* IE 6/7 */
      clip: rect(1px, 1px, 1px, 1px);
      height: 1px;
      overflow: hidden;
      position: absolute;
      white-space: nowrap; /* added line */
      width: 1px;
    }
    &.selected {
      border: 1px solid $red;
      background: $red;
      color: white;
    }
    &.reserved {
      background: rgb(200,200,200);
      color: rgb(100,100,100);
      font-weight: 400;
      text-decoration: line-through;
    }
  }
  .submit-wrapper {
    max-width: 720px;
    padding: 1em;
    box-sizing: border-box;
  }
  input.submit-button {
    border-radius: 0.5em;
    max-width: 720px;
    background: $red;
    color: white;
    font: inherit;
    width: 100%;
    display: block;
    cursor: pointer;
    // margin: 0.5em;
    box-sizing: border-box;
    
    padding: 0.8em 0;
    
  }
}
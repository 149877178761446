@import url("https://use.typekit.net/plv7vax.css");

@import './_vars.scss';

html, body {
  background:  $pink !important;
}


.cravesushi {
  // background-color: $white;
  background-color: #f9f9f9;
  // background-color: #ffffff;
  // background-color: #ffffff;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 56 28' width='56' height='28'%3E%3Cpath fill='%23f2f2f2' fill-opacity='0.4' d='M56 26v2h-7.75c2.3-1.27 4.94-2 7.75-2zm-26 2a2 2 0 1 0-4 0h-4.09A25.98 25.98 0 0 0 0 16v-2c.67 0 1.34.02 2 .07V14a2 2 0 0 0-2-2v-2a4 4 0 0 1 3.98 3.6 28.09 28.09 0 0 1 2.8-3.86A8 8 0 0 0 0 6V4a9.99 9.99 0 0 1 8.17 4.23c.94-.95 1.96-1.83 3.03-2.63A13.98 13.98 0 0 0 0 0h7.75c2 1.1 3.73 2.63 5.1 4.45 1.12-.72 2.3-1.37 3.53-1.93A20.1 20.1 0 0 0 14.28 0h2.7c.45.56.88 1.14 1.29 1.74 1.3-.48 2.63-.87 4-1.15-.11-.2-.23-.4-.36-.59H26v.07a28.4 28.4 0 0 1 4 0V0h4.09l-.37.59c1.38.28 2.72.67 4.01 1.15.4-.6.84-1.18 1.3-1.74h2.69a20.1 20.1 0 0 0-2.1 2.52c1.23.56 2.41 1.2 3.54 1.93A16.08 16.08 0 0 1 48.25 0H56c-4.58 0-8.65 2.2-11.2 5.6 1.07.8 2.09 1.68 3.03 2.63A9.99 9.99 0 0 1 56 4v2a8 8 0 0 0-6.77 3.74c1.03 1.2 1.97 2.5 2.79 3.86A4 4 0 0 1 56 10v2a2 2 0 0 0-2 2.07 28.4 28.4 0 0 1 2-.07v2c-9.2 0-17.3 4.78-21.91 12H30zM7.75 28H0v-2c2.81 0 5.46.73 7.75 2zM56 20v2c-5.6 0-10.65 2.3-14.28 6h-2.7c4.04-4.89 10.15-8 16.98-8zm-39.03 8h-2.69C10.65 24.3 5.6 22 0 22v-2c6.83 0 12.94 3.11 16.97 8zm15.01-.4a28.09 28.09 0 0 1 2.8-3.86 8 8 0 0 0-13.55 0c1.03 1.2 1.97 2.5 2.79 3.86a4 4 0 0 1 7.96 0zm14.29-11.86c1.3-.48 2.63-.87 4-1.15a25.99 25.99 0 0 0-44.55 0c1.38.28 2.72.67 4.01 1.15a21.98 21.98 0 0 1 36.54 0zm-5.43 2.71c1.13-.72 2.3-1.37 3.54-1.93a19.98 19.98 0 0 0-32.76 0c1.23.56 2.41 1.2 3.54 1.93a15.98 15.98 0 0 1 25.68 0zm-4.67 3.78c.94-.95 1.96-1.83 3.03-2.63a13.98 13.98 0 0 0-22.4 0c1.07.8 2.09 1.68 3.03 2.63a9.99 9.99 0 0 1 16.34 0z'%3E%3C/path%3E%3C/svg%3E");
  .page {
    min-height: 100vh;
  }
  .page-header {
    max-width: $max-site-width;
    margin: 1em auto;
    padding-left: 0.2em;
    padding-bottom: 0;
    h1 {
      text-transform: uppercase;
      font-size: 1.5em;
      color: $red;
    }
    border-bottom: 2px solid $red;
    display: flex;
    align-items: center;
    .text {
      flex: 1;
    }
    .logo-wrapper {
      width: 100px;
      height: 98px;
      // justify-self: flex-end;
      margin: 0;
      align-self: flex-end;
      svg {
        // border-bottom: 2px solid black;
        display: inline-block;
        margin: 0;
        padding: 0;
      }
    }
  }
  .product-link {
    background: $red;
    border: none;
    color: $white;
    display: flex;
    font-weight: 600;
    align-items: center;
    .plus {
      font-weight: 200;
      position: relative;
      top: -0.06em;
      font-size: 2em;
      line-height: 0.3em;
    }
    &:visited {
      color: $white;
    }
  }

  font-family: $signatureFont;
  background-color: $background-color;
  svg.nav-bar-logo {
    display: block;
    position: relative;
    // height: 35px;
    // margin-top: -0.4em;
    // bottom: -0.6em;
    // padding-top: 0.23em;
    // padding-left: 0.3em;
    // padding-bottom: 0.023em;
  }
  .nav-bar-logo {

    // background: blue;
    height: 55px;
    padding-bottom: 0;
    display: block;
    // margin-bottom: -0.2em;

    // margin-left: 0.3em;
  }
  .tool-bar {
    padding-top: 0.35em;
    // display: none;

  }

  .cart.minimized {
    // background: rgba(237,237,237, 0.8);
    // display: none;
    padding-left: 0;
    padding-top: 0;
    background-color: $white;
    padding-bottom: 0;
    margin-bottom: 0;
    border-bottom: none;
  }

  a.checkout-button.button {
    background: $pink;
    text-transform: capitalize;

    color: $white;
    font-weight: 600;
  }

  .category__header {
    text-align: left;
    max-width: 840px;
    margin: 0 auto;

    border-bottom: 1.5px solid black;
    // padding: 0 0.5em;
    /* background: rgba(237,237,237, 0.4); */

  }
  .category-name {
    margin-left: 0em;
    padding-left: 0.2em;
  }
  .category__description {
    text-align: left;
    margin-left: 0em;
    padding-left: 0.2em;
    padding-bottom: 0.5em;
    max-width: none;
  }

  .category-name .text {
    font-family: $deco;
    font-weight: 600;
    // text-decoration: underline;
    // text-decoration-thickness: 2px;
    font-size: 1.5em;
    padding: 0;
    background: none;
    color: black;

  }
  .category-name .text::before {
    // content: "☙"
  }
  .menu-category-list-view {
    .photos.grid {

      justify-content: space-between;
    }
    .menu-item {
      background: none;
      // align-items: center;
      .photo-container {
        width: 300px;
      }
      .food-name {

        font-weight: 600;
        line-height: 1.5em;

      }
      @media (min-width: 550px) {
        width: 49%
      }

    }

    .content {
      flex: auto;
    }


    .price {
      // color: $red;
      // &::before {
      //   content: "..."
      // }
    }
    a.product-link {
      // align-self: center;
      margin-right: 0.9em;
      font-size: 0.8em;

    }
  }



  .price-summary {
    padding-bottom: 5em;
  }
  //
  //
  .pages-nav {
    font-family: $deco;
    background: $black;
    font-weight: 400;
    font-size: 0.8em;

    ul {
      display: flex;
      flex-wrap: wrap;
    }
    @media screen and (max-width: 480px) {
      font-size: 4vw;
    }
  }

  a.page-link {
    $borderWidth: 3px;
    // padding-top: 0.5em;
    $paddingY: 0.35em;
    text-decoration: none;
    color: $white;
    display: block;
    padding: $paddingY 0.3em;
    padding: $paddingY 0.3em calc(#{$paddingY} - #{$borderWidth}) 0.3em;
    // box-sizing: border-box;
    border-bottom: $borderWidth solid rgba(100,100,100,0);
    &:hover, &.active {
      border-bottom: $borderWidth solid $white;
    }
  }

  .page-content {
    max-width: 600px;
    margin: 1em auto;
    h1 {
      font-weight: 600;
      font-size: 1.5em;
      font-family: $deco;
    }
  }

  .full-menu-link-wrapper {
    padding: 1em 0;
    text-align: center;
    a.button {
      background: $pink;
      color: $white;
    }
  }

  .mag-glass-svg {
    display: none !important;
  }


  .footer {
    font-family: $signatureFont;
    margin-top: 2em;
    // border-top: 4px solid $pink;

    // background: $white;
    // color: black;
    a.phone-link {
      color: $pink;
    }
  }
}
